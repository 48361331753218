import React, { useState, useEffect } from "react"
import { Row, Col, Button, Input, InputGroup, InputGroupAddon } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Loading } from "../../components/loading"
import { useSearch } from "../../hooks/use-search"
import { fetch_get } from "../../utils/fetch"
import { checkPassword } from "../../utils/common"
import { MdClose } from "react-icons/md"
import { FaSearch } from "react-icons/fa"
import { PropagateLoader } from "react-spinners"
import { Link } from "gatsby"
import "../../styles/faq.scss"
import { useAuth0 } from "@auth0/auth0-react"

const FAQ = () => {
  const { loading } = useUserOrgs()
  const { isAuthenticated, user } = useAuth0()
  const [pageLoading, setPageLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [searchString, setSearchString] = useState("")
  const [tags, setTags] = useState([])
  const [faq, setFaq] = useState([])
  const callback = v => {
    setSearchString(v?.target?.value || "")
  }

  const { search, setSearch, callback: handleSearchValue } = useSearch(callback)

  useEffect(() => {
    if (checkPassword(isAuthenticated, user)) return;
    setPageLoading(true)
    setErrorMessage("")
    setTags([])
    setFaq([])
    if (searchString) {
      fetch_get(`faqs/search?contains=${searchString}`, false)
        .then(response => {
          setFaq(response.data)
          setPageLoading(false)
        })
        .catch(error => {
          console.log(error)
          setErrorMessage("An error occurred while getting the list of faq.")
          setPageLoading(false)
        })
    } else {
      fetch_get("faqs", false)
        .then(response => {
          setTags(response.tags)
          setFaq(response.data)
          setPageLoading(false)
        })
        .catch(error => {
          console.log(error)
          setErrorMessage("An error occurred while getting the list of faq.")
          setPageLoading(false)
        })
    }
  }, [searchString]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />
  }

  const emptySearchValue = () => {
    setSearchString("")
  }

  return (
    <Layout pageTitle="FAQ">
      <Header contentPage={true} className="contentPage">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <h1 className="display-4 font-weight-bold">Ask me!</h1>
          </div>
          <div
            className="d-flex align-items-center"
            style={{ flex: 1, margin: "0px 30% 0px 15px" }}
          >
            <InputGroup>
              <Input
                value={search}
                placeholder="Search the FAQ..."
                onChange={event => handleSearchValue(event)}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    event.preventDefault()
                    document.getElementById("searchButton").click()
                  }
                }}
              />
              {search !== "" && (
                <InputGroupAddon className="clear_search" addonType="append">
                  <Button
                    onClick={() => {
                      setSearch("")
                      emptySearchValue()
                    }}
                  >
                    <MdClose />
                  </Button>
                </InputGroupAddon>
              )}
              <InputGroupAddon addonType="append">
                <Button id="searchButton">
                  <FaSearch />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
      </Header>
      <Content>
        {pageLoading ? (
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <PropagateLoader
                className="align-self-center"
                size={20}
                color={"#9c3"}
                loading={true}
              />
            </Col>
          </Row>
        ) : errorMessage ? (
          <p>{errorMessage}</p>
        ) : searchString ? (
          <>
            {faq.map(f => {
              let objE = document.createElement("div")
              objE.innerHTML = f.content
              return (
                <>
                  <h5 className={`font-weight-bold faqtitle`}>
                    <Link to={`/faq/${f.id}/`}>
                      <span>{f.title}</span>
                    </Link>
                  </h5>
                  {/* <div dangerouslySetInnerHTML={{ __html: f.content }}></div> */}
                  <p>
                    {objE.textContent.length > 254
                      ? objE.textContent.substring(0, 253) + "..."
                      : objE.textContent}
                  </p>
                </>
              )
            })}
          </>
        ) : (
          <>
            {tags.map(t => (
              <div className="section">
                <div class="section-image"></div>
                <h3>{t.name}</h3>
                <ul className="article-list">
                  {faq.map(f =>
                    f.tagIDs.includes(t.id) ? (
                      <li>
                        <Link to={`/faq/${f.id}/`}>{f.title}</Link>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            ))}
          </>
        )}
      </Content>
    </Layout>
  )
}

export default FAQ
